import React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 80 100"
      className="c-icon c-icon--scale"
      preserveAspectRatio="xMinYMid meet"
    >
      <rect x="32" y="12" width="45" height="45" rx="7" ry="7" />
      <rect x="17" y="28" width="45" height="45" rx="7" ry="7" />
      <rect x="1" y="43" width="45" height="45" rx="7" ry="7" />
    </svg>
  )
}
