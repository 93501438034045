import React from 'react'
import { graphql } from 'gatsby'
import { Helmet, useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../../components/layout'
import Prefooter from '../../components/prefooter'
import SectionHeader from '../../components/section-header'
import PageHeader from '../../components/page-header'
import PageIntro from '../../components/page-intro'
import PageSteps from '../../components/page-steps'
import PageClient from '../../components/page-client'
import ColorSwitch from '../../components/waypoint-color-switch'
import Drift from '../../components/waypoint-drift'

import ScaleIcon from '../../components/Icons/Scale'

function ProjectBasedConsultingPage({ data }) {
  const [t, { language }] = useTranslation(['translations', 'seo'])
  const clients = data.allPrismicClients.edges.map(item => item.node)
  return (
    <React.Fragment>
      <Helmet
        title={t('ProductConsultingPage.title', { ns: 'seo' })}
        meta={[
          {
            name: 'description',
            content: t('ProductConsultingPage.title', { ns: 'seo' }),
          },
          { name: 'keywords', content: 'versett, project, consulting' },
          {
            property: 'og:title',
            content: t('ProductConsultingPage.title', { ns: 'seo' }),
          },
          {
            property: 'og:description',
            content: t('ProductConsultingPage.title', { ns: 'seo' }),
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-consulting.jpg',
          },
          {
            property: 'og:url',
            content: 'https://versett.com/services/project-based-consulting/',
          },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Product Consulting' },
        ]}
      >
        <html lang={language} className="s-page s-page--scale" />
      </Helmet>
      <Layout>
        <section className="s-container s-page__hero s-page__hero--short">
          <PageHeader
            lede={t('ConsultingService.heading')}
            subheading={t('ConsultingService.subheading')}
          />
        </section>
        <section className="s-container">
          <div className="s-page__icon">
            <ScaleIcon />
          </div>
        </section>
        <ColorSwitch />
        <Drift />
        <div>
          <PageIntro>
            <h4>{t('ConsultingService.description')}</h4>
          </PageIntro>
          <section className="s-section s-container grid grid--2col-1-1">
            <div>
              <img src="/images/consulting-01.jpg" alt="" />
            </div>
            <div>
              <img src="/images/consulting-02.jpg" alt="" />
            </div>
          </section>
          <PageSteps
            callToAction={t('ConsultingService.projectWorkLink')}
            heading={t('ConsultingService.projectWorkHeading')}
          >
            <div>
              <dl>
                {t('ConsultingService.projectWorkTasks', {
                  returnObjects: true,
                }).map(task => (
                  <React.Fragment key={task.term}>
                    <dt>{task.term}</dt>
                    {task.details.map(detail => (
                      <dd key={detail}>{detail}</dd>
                    ))}
                  </React.Fragment>
                ))}
              </dl>
            </div>
          </PageSteps>
          <section className="s-container s-section s-page__clients">
            <SectionHeader
              lede={t('ConsultingService.projectExamplesHeading')}
              classes="t-section-heading--less-bottom"
            />
            {clients.map(client => (
              <PageClient key={client.id} client={client} page="Consulting" />
            ))}
          </section>
        </div>
        <Prefooter />
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  query consultingQueries($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicClients(
      sort: { fields: [data___weight], order: ASC }
      filter: {
        data: {
          page_specific: { elemMatch: { page_type: { eq: "Consulting" } } }
        }
        lang: { eq: $language }
      }
    ) {
      edges {
        node {
          id
          data {
            name
            page_specific {
              page_type
              content {
                document {
                  ... on PrismicClientServiceExamples {
                    data {
                      description
                      project_name
                      images {
                        image {
                          url
                          thumbnails {
                            small_1x {
                              url
                            }
                            small_2x {
                              url
                            }
                            medium_1x {
                              url
                            }
                            medium_2x {
                              url
                            }
                            large_1x {
                              url
                            }
                            large_2x {
                              url
                            }
                          }
                        }
                      }
                      video {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ProjectBasedConsultingPage
